// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-irythia-hs-js": () => import("./../../../src/pages/projects/irythia-hs.js" /* webpackChunkName: "component---src-pages-projects-irythia-hs-js" */),
  "component---src-pages-projects-monkey-spin-js": () => import("./../../../src/pages/projects/monkey-spin.js" /* webpackChunkName: "component---src-pages-projects-monkey-spin-js" */),
  "component---src-pages-projects-phoenix-rising-js": () => import("./../../../src/pages/projects/phoenix-rising.js" /* webpackChunkName: "component---src-pages-projects-phoenix-rising-js" */),
  "component---src-pages-projects-typoo-defender-js": () => import("./../../../src/pages/projects/typoo-defender.js" /* webpackChunkName: "component---src-pages-projects-typoo-defender-js" */),
  "component---src-pages-projects-video-plays-js": () => import("./../../../src/pages/projects/video-plays.js" /* webpackChunkName: "component---src-pages-projects-video-plays-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

